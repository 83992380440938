import * as React from 'react';
import * as Styles from '../../styles/components/_burger.login.module.scss';
import buttonImage from '../../images/btn_users_sp.png'
import {Link, useIntl} from "gatsby-plugin-react-intl"

const BurgerLogin = ({ open, setOpen, openLogin, setOpenLogin}) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  return (
    <div>
      <div id="burger-menu-not-login" className={Styles.loginButton_wrapper}>
        <Link
          className={`${Styles.loginButton} ${Styles.for_sp}`}
          to="/users/login"
        >
          {t('users.menu.login')}
        </Link>
      </div>
      <div id="burger-menu-logged-in" className={Styles.loginButton_wrapper}>
        <button
          onClick={() => setOpenLogin(!openLogin)}
          className={`${Styles.loginButton_users} ${(open || openLogin) && Styles.menuOpen}`}
        >
          <img src={buttonImage} alt={""} />
        </button>
      </div>
    </div>
  );
};

export default BurgerLogin;
