// extracted by mini-css-extract-plugin
export var header = "_header-module--header--OY+B7";
export var current_page = "_header-module--current_page--wmKNV";
export var page_none = "_header-module--page_none--09YB7";
export var header__container = "_header-module--header__container--LCRQE";
export var header__logo = "_header-module--header__logo--YqZm0";
export var header__dropdown = "_header-module--header__dropdown--hv+p-";
export var header__border = "_header-module--header__border--olvw8";
export var header__btn__sign_in = "_header-module--header__btn__sign_in--wnbvG";
export var header__btn__login = "_header-module--header__btn__login--FhQsy";
export var header__btn__artist = "_header-module--header__btn__artist--82NGO";
export var header__dropdown_login = "_header-module--header__dropdown_login--MqCIp";
export var logout = "_header-module--logout--7QybW";