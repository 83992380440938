import * as React from 'react';
import {Link, navigate, useIntl} from "gatsby-plugin-react-intl"
import * as Styles from '../../styles/components/_menu.module.scss';
import {logout} from "../../services/auth";

const MenuLogin = ({ openLogin }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const onClickLogout = async () => {
    await logout()
    await navigate('/')
  }

  return (
    <nav className={`${Styles.burgerMenu} ${openLogin && Styles.menuOpen}`}>
      <ul>
        <li id="burger-menu-not-artist" className={`${Styles.burgerMenu__item} ${Styles.burgerMenu__artist}`}>
          <Link to="/artworks/signup">{t('users.menu.registerArtist')}</Link>
        </li>
        <li id="burger-menu-artist-1" className={`${Styles.burgerMenu__item} ${Styles.burgerMenu__artist}`}>
          <Link to="/artworks/signup">{t('users.menu.artistSetting')}</Link>
        </li>
        <li id="burger-menu-artist-2" className={`${Styles.burgerMenu__item} ${Styles.burgerMenu__artist}`}>
          <Link to="/artworks">{t('users.menu.artwork')}</Link>
        </li>
        <li className={Styles.burgerMenu__item}>
          <Link to="/users/loginsetting">{t('users.menu.loginSetting')}</Link>
        </li>
        <li>
          <Link to="/users/setting">{t('users.menu.userSetting')}</Link>
        </li>
        <li>
          <Link to="/users/logout">{t('users.menu.logout')}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuLogin;
