import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {Link, useIntl} from "gatsby-plugin-react-intl"

import * as Styles from '../../styles/components/_menu.module.scss';
import { contentPathHelper } from '../../utils/contentPathHelper';

const Menu = ({ open }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const { allMicrocmsSeason } = useStaticQuery(
    graphql`
      query {
        allMicrocmsSeason(sort: { order: ASC, fields: publishedAt }, filter: {is_private: {eq: false}}) {
          edges {
            node {
              seasonId
              publishedAt
            }
          }
        }
      }
    `
  );

  const seasonList = React.useMemo(() => {
    return allMicrocmsSeason.edges.length > 0 ? allMicrocmsSeason.edges.map((node) => node) : [];
  }, []);

  return (
    <nav className={`${Styles.burgerMenu} ${open && Styles.menuOpen}`}>
      <ul>
        <li className={Styles.burgerMenu__item}>
          <BurgerDropdownMenu title={t('menu.firstLIMITS')}>
            <li>
              <Link to="/about/">{t('menu.aboutLIMITS')}</Link>
            </li>
            <li>
              <Link to="/history/">{t('menu.soFarLIMITS')}</Link>
            </li>
            <li>
              <Link to="/entry/">{t('menu.joinLIMITS')}</Link>
            </li>
            <li>
              <Link to="/faq/">{t('menu.faq')}</Link>
            </li>
          </BurgerDropdownMenu>
        </li>
        <li className={Styles.burgerMenu__item}>
          <Link to="/news/">{t('menu.news')}</Link>
        </li>
        <li className={Styles.burgerMenu__item}>
          <BurgerDropdownMenu title={t('menu.match')}>
            <li>
              <Link to="/schedule/">{t('menu.schedule')}</Link>
            </li>
            <li>
              <Link to="/match/">{t('menu.result')}</Link>
            </li>
          </BurgerDropdownMenu>
        </li>
        {seasonList[0] && (
            <li className={Styles.burgerMenu__item}>
              <Link to={`/season/${contentPathHelper(seasonList[0].node.seasonId)}/`}>{t('menu.seasonInformation')}</Link>
            </li>
          )}
        <li className={Styles.burgerMenu__item}>
          <Link to="/gallery/">{t('menu.gallery')}</Link>
        </li>
        <li>
          <Link to="/artist/">{t('menu.artist')}</Link>
        </li>
      </ul>
    </nav>
  );
};

const BurgerDropdownMenu = ({ title, children }) => {
  const [reactTransitionGroupOpen, setReactTransitionGroupOpen] = React.useState(false);
  const toggleReactTransitionGroupOpen = () => setReactTransitionGroupOpen((reactTransitionGroupOpen) => !reactTransitionGroupOpen);

  const transitionStyle = {
    entering: {
      height: 'auto',
      opacity: '0',
      transition: 'all .3s ease',
    },
    entered: {
      height: 'auto',
      opacity: '1',
      transition: 'all .3s ease',
    },
    exiting: {
      height: 'auto',
      opacity: '0',
      transition: 'all .3s ease',
    },
    exited: {
      height: '0',
      opacity: '0',
      transition: 'all .3s ease',
    },
  };

  const menuRef = React.useRef();

  return (
    <div className={Styles.burger_dropdownMenu}>
      <button onClick={() => toggleReactTransitionGroupOpen()}>
        {title}
        <span className={classNames(Styles.burger_dropdownMenu__triangle, reactTransitionGroupOpen && Styles.burger_dropdownMenu__triangle__open)} />
      </button>

      <CSSTransition in={reactTransitionGroupOpen} timeout={200} classNames="react-transition-group" unmountOnExit>
        {(state) => (
          <ul ref={menuRef} tabIndex={1} className={Styles.dropdown_menu__ul} style={transitionStyle[state]} onBlur={() => setTimeout(() => toggleReactTransitionGroupOpen(), 100)}>
            {children}
          </ul>
        )}
      </CSSTransition>
    </div>
  );
};

export default Menu;
