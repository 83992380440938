import * as React from 'react';
import PropTypes from 'prop-types';
import * as Styles from '../styles/components/_footer.module.scss';
import Twitter from '/static/twitter.svg';
import Line from '/static/line.svg';
import Youtube from '/static/youtube.svg';
import Facebook from '/static/facebook.svg';
import Instagram from '/static/insta.svg';
import Logo from '/static/logo.svg';
import {Link, useIntl} from "gatsby-plugin-react-intl"

export const Footer = ({ siteTitle, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const currentClass = (path, currentPath = location.pathname) => {
    // pathとlocation.pathname(現在いるページのpath)が一致しているか判定
    if (path !== currentPath) {
      // 一致していればclassを返す
      return null;
    }

    return Styles.current_page;
  };

  return (
    <footer className={Styles.footer}>
      <div className={Styles.footer__border}></div>
      <div className={Styles.footer__container}>
        <div>
          <p className={Styles.footer__logo}>
            <Link to="/">
              <img src={Logo} className="logo" alt={siteTitle} />
            </Link>
          </p>
          <small>Copyright &copy; 2015 P.A.I.N.T. Inc. All Rights Reserved.</small>
          <p>No part of this site may be reproduced, stored in a retrieval system or transmitted in any way or by any means (including photocopying, recording or storing it in any medium by electronic means), without the written permission of P.A.I.N.T. Inc.</p>
        </div>
        <div>
          <ul>
            <li className={currentClass('/faq/')}>
              <Link to="/faq/">{t('footer.faq')}</Link>
            </li>
            <li className={currentClass('/contact/')}>
              <Link to="/contact/">{t('footer.inquiry')}</Link>
            </li>
            <li className={currentClass('/company/')}>
              <Link to="/company/">{t('footer.operationCompany')}</Link>
            </li>
            <li className={currentClass('/users/terms/')}>
              <Link to="/users/terms">{t('footer.terms')}</Link>
            </li>
            <li className={currentClass('/users/privacy-policy/')}>
              <Link to="/users/privacy-policy">{t('footer.privacyPolicy')}</Link>
            </li>
          </ul>
          <div className={Styles.footer__sns}>
            <a href="http://twitter.com/LIMITS_DABA" target="_blank">
              <img src={Twitter} className="twitter" alt="twitter" />
            </a>
            <a href="https://line.me/R/ti/p/%40limits" target="_blank">
              <img src={Line} className="line" alt="line" />
            </a>
            <a href="https://www.youtube.com/c/limits_ch" target="_blank">
              <img src={Youtube} className="youtube" alt="youtube" />
            </a>
            <a href="https://www.facebook.com/limits.daba/" target="_blank">
              <img src={Facebook} className="facebook" alt="facebook" />
            </a>
            <a href="https://www.instagram.com/limits_artbattle/" target="_blank">
              <img src={Instagram} className="instagram" alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
