import * as React from 'react';
import * as Styles from '../../styles/components/_burger.module.scss';

const Burger = ({ open, setOpen, openLogin, setOpenLogin}) => {
  const onClick = () => {
    if (open) {
      setOpen(false)
    } else if (openLogin) {
      setOpenLogin(false)
    } else {
      setOpen(true)
    }
  }
  return (
    <div>
      <button onClick={onClick} className={`${Styles.burgerButton} ${(open || openLogin) && Styles.menuOpen}`}>
        <div />
        <div />
        <div />
      </button>
    </div>
  );
};

export default Burger;
