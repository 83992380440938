import * as React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import { DropdownMenu } from './dropdown-menu';
import Logo from '/static/logo.svg';
import Down from '/static/down.svg';
import * as Styles from '../styles/components/_header.module.scss';
import classNames from 'classnames';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../utils/contentPathHelper'
import menuIcon from '../images/icon_users_pc.png'
import { DropdownMenuLogin } from './dropdown-menu-login';
import {logout} from "../services/auth";
// import Language from "./language";

export const Header = ({ siteTitle, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const currentClass = (path, currentPath = location.pathname) => {
    // pathとlocation.pathname(現在いるページのpath)が一致しているか判定
    if (path !== currentPath) {
      // 一致していればclassを返す
      return null;
    }
    return Styles.current_page;
  };

  const { allMicrocmsSeason } = useStaticQuery(
    graphql`
      query {
        allMicrocmsSeason(sort: { order: ASC, fields: publishedAt }, filter: {is_private: {eq: false}}) {
          edges {
            node {
              seasonId
              publishedAt
            }
          }
        }
      }
    `
  );

  const seasonList = React.useMemo(() => {
    return allMicrocmsSeason.edges.length > 0 ? allMicrocmsSeason.edges.map((node) => node) : [];
  }, []);

  const onClickLogout = async () => {
    await navigate('/users/logout')
  }

  return (
    <header className={Styles.header}>
      <div className={Styles.header__container}>
        {location.pathname === '/' ? (
          <h1 className={Styles.header__logo}>
            <img src={Logo} className="logo" alt={siteTitle} />
          </h1>
        ) : (
          <p className={Styles.header__logo}>
            <Link to="/">
              <img src={Logo} className="logo" alt={siteTitle} />
            </Link>
          </p>
        )}

        <ul>
          {/*<li className={Styles.header__dropdown}>*/}
          {/*  <Language />*/}
          {/*</li>*/}
          <li className={Styles.header__dropdown}>
            <DropdownMenu title={t('menu.firstLIMITS')}>
              <li className={currentClass('/about/')}>
                <Link to="/about/">{t('menu.aboutLIMITS')}</Link>
              </li>
              <li className={currentClass('/history/')}>
                <Link to="/history/">{t('menu.soFarLIMITS')}</Link>
              </li>
              <li className={currentClass('/entry/')}>
                <Link to="/entry/">{t('menu.joinLIMITS')}</Link>
              </li>
              <li className={currentClass('/faq/')}>
                <Link to="/faq/">{t('menu.faq')}</Link>
              </li>
            </DropdownMenu>
          </li>
          <li className={currentClass('/news/')}>
            <Link to="/news/">{t('menu.news')}</Link>
          </li>
          <li className={`${Styles.header__dropdown}`}>
            <DropdownMenu title={t('menu.match')}>
              <li className={currentClass('/about/')}>
                <Link to="/schedule/">{t('menu.schedule')}</Link>
              </li>
              <li className={currentClass('/history/')}>
                <Link to="/match/">{t('menu.result')}</Link>
              </li>
            </DropdownMenu>
          </li>
          {seasonList[0] && (
            <li className={classNames(currentClass('/season/'), seasonList === [] && Styles.current_page)}>
              <Link to={`/season/${contentPathHelper(seasonList[0].node.seasonId)}/`}>{t('menu.seasonInformation')}</Link>
            </li>
          )}
          <li className={currentClass('/gallery/')}>
            <Link to="/gallery/">{t('menu.gallery')}</Link>
          </li>
          <li className={currentClass('/artist/')}>
            <Link to="/artist/">{t('menu.artist')}</Link>
          </li>
          <li id="menu-not-login" className={Styles.header__btn__sign_in}>
            <Link to="/users/login">{t('users.menu.login')}</Link>
          </li>
          <li id="menu-logged-in" className={`${Styles.header__btn__login} ${Styles.header__dropdown} ${Styles.header__dropdown_login}`}>
            <div id="menu-not-artist" className={Styles.header__btn__artist}>
              <DropdownMenuLogin>
                <li className={currentClass('/artworks/signup')}>
                  <Link to="/artworks/signup">{t('users.menu.registerArtist')}</Link>
                </li>
                <li className={currentClass('/users/loginsetting')}>
                  <Link to="/users/loginsetting">{t('users.menu.loginSetting')}</Link>
                </li>
                <li className={currentClass('/users/setting')}>
                  <Link to="/users/setting">{t('users.menu.userSetting')}</Link>
                </li>
                <li className={currentClass('/logout')}>
                  <div className={Styles.logout} onClick={onClickLogout}>{t('users.menu.logout')}</div>
                </li>
              </DropdownMenuLogin>
            </div>
            <div id="menu-artist" className={Styles.header__btn__artist}>
              <DropdownMenuLogin>
                <li className={currentClass('/artworks')}>
                  <Link to="/artworks/signup">{t('users.menu.artistSetting')}</Link>
                </li>
                <li className={currentClass('/artist/artwork')}>
                  <Link to="/artworks">{t('users.menu.artwork')}</Link>
                </li>
                <li className={currentClass('/users/loginsetting')}>
                  <Link to="/users/loginsetting">{t('users.menu.loginSetting')}</Link>
                </li>
                <li className={currentClass('/users/setting')}>
                  <Link to="/users/setting">{t('users.menu.userSetting')}</Link>
                </li>
                <li className={currentClass('/logout')}>
                  <div className={Styles.logout} onClick={onClickLogout}>{t('users.menu.logout')}</div>
                </li>
              </DropdownMenuLogin>
            </div>
          </li>
        </ul>
      </div>
      <div className={Styles.header__border}></div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
