import * as React from 'react';
import Down from '/static/down.svg';
import { CSSTransition } from 'react-transition-group';
import * as Styles from '../styles/components/_dropdown_menu_login.module.scss';
import classNames from 'classnames';
import icon from '../images/btn_users_sp.png'

export const DropdownMenuLogin = ({ title, children }) => {
  const [reactTransitionGroupOpen, setReactTransitionGroupOpen] = React.useState(false);
  const toggleReactTransitionGroupOpen = () => setReactTransitionGroupOpen((reactTransitionGroupOpen) => !reactTransitionGroupOpen);

  const transitionStyle = {
    entering: {
      opacity: '0',
      transition: 'all .3s ease',
    },
    entered: {
      opacity: '1',
      transition: 'all .3s ease',
    },
    exiting: {
      opacity: '0',
      transition: 'all .3s ease',
    },
    exited: {
      opacity: '1',
      transition: 'all .3s ease',
    },
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const menuRef = React.useRef();

  return (
    <div className={Styles.dropdown_menu}>
      <button onMouseEnter={() => setReactTransitionGroupOpen(true)} onMouseLeave={() => setReactTransitionGroupOpen(false)}>
        <img
          src={icon}
          width='32'
          height='32'
          alt="icon"
        />
        <img
          src={Down}
          className={classNames(reactTransitionGroupOpen && Styles.dropdown_menu__down)}
          alt="down"
          width='16'
          height='16'
        />
      </button>

      <CSSTransition in={reactTransitionGroupOpen} timeout={200} classNames="react-transition-group" unmountOnExit>
        {(state) => (
          <ul ref={menuRef} tabIndex={1} className={Styles.dropdown_menu__ul} style={transitionStyle[state]} onMouseEnter={() => setReactTransitionGroupOpen(true)} onMouseLeave={() => setReactTransitionGroupOpen(false)}>
            {children}
          </ul>
        )}
      </CSSTransition>
    </div>
  );
};

export default DropdownMenuLogin;
