import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Header } from './header';
import { Footer } from './footer';
import Menu from './hamburger/menu';
import Burger from './hamburger/burger';
import BurgerLogin from './hamburger/burgerLogin';
import MenuLogin from './hamburger/menuLogin';

export const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [openLogin, setOpenLogin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const burger = React.useRef();

  return (
    <>
      <div ref={burger} className="burger_component">
        <Burger open={open} setOpen={setOpen} openLogin={openLogin} setOpenLogin={setOpenLogin}/>
        <Menu open={open} setOpen={setOpen} />
        <BurgerLogin open={open} setOpen={setOpen} openLogin={openLogin} setOpenLogin={setOpenLogin} />
        <MenuLogin openLogin={openLogin} setOpenLogin={setOpenLogin} />
      </div>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} location={location} />
      <main>{children}</main>
      <Footer location={location} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
